import * as React from 'react'
import io from 'socket.io-client'
import { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react'
import FlipMove from 'react-flip-move'
import { useAuth } from '../hooks/useAuth'
import { StaticImage } from 'gatsby-plugin-image'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import LeaderboardItem from '../components/leaderboardItem'
import { WindowHeightContext } from '../context/windowHeightContext'
import { getAll } from '../services/leaderboards'
import { getGroup, getGroupBy7RId } from '../services/group'
import {
	calculateWidthValue,
	calculateHeightValue
} from '../helpers/dimensionTransformer'
import eventTracking from '../services/eventTracking'
const socket = io.connect(process.env.REACT_APP_WEBSOCKET_URL)

const VenueTVPage = ({ bookingId }) => {
	const queryClient = useQueryClient()
	const { profile } = useAuth()
	const height = useContext(WindowHeightContext)
	const [players, setPlayers] = useState([])
	const [playersTwo, setPlayersTwo] = useState([])
	const [teams, setTeams] = useState([])
	const [countGamesPlayed, setCountGamesPlayed] = useState([])
	const [countGamesTeamPlayed, setCountGamesTeamPlayed] = useState([])
	const [clock, setClock] = useState(1)

	const bookingQuery = useQuery({
		queryKey: ['booking', bookingId],
		queryFn: () => getGroupBy7RId(bookingId)
	})

	const playersQuery = useQuery(
		['leaderboards', 60, 0, null, 'group', bookingQuery?.data?.group?.id],
		getAll,
		{
			enabled: !!bookingQuery?.data?.group?.id
		}
	)

	const teamsQuery = useQuery(
		['leaderboards', 60, 1, null, 'group', bookingQuery?.data?.group?.id],
		getAll,
		{ enabled: !!bookingQuery?.data?.group?.id }
	)
	const maxPlayersPage = Math.ceil(
		((playersQuery.data?.leaderboard || []).length || 1) / 10
	)
	const maxTeamsPage = Math.ceil(
		((teamsQuery.data?.leaderboard || []).length || 1) / 10
	)
	const hasTeams = (teamsQuery.data?.leaderboard || []).length > 1

	const playersPage =
		clock - Math.floor((clock - 1) / maxPlayersPage) * maxPlayersPage - 1
	const playersPageOne = hasTeams
		? playersPage
		: playersPage % 2
		? playersPage - 1
		: playersPage
	const playersPageTwo = hasTeams
		? 0
		: playersPage % 2
		? playersPage
		: playersPage + 1

	const teamsPage =
		clock - Math.floor((clock - 1) / maxTeamsPage) * maxTeamsPage - 1

	useEffect(() => {
		const allPlayers = playersQuery.data?.leaderboard || []

		if (playersQuery.data?.countGamesPlayed) {
			setCountGamesPlayed(playersQuery.data?.countGamesPlayed)
		}
		if (playersQuery.data?.countGamesTeamPlayed) {
			setCountGamesTeamPlayed(playersQuery.data?.countGamesTeamPlayed)
		}
		if (playersPageOne === 0 && allPlayers.length > 0) {
			setPlayers(allPlayers.slice(0, 10))
			if (maxTeamsPage < 1 && maxPlayersPage > 1) {
				setPlayersTwo(allPlayers.slice(10, 20))
			}
		}
	}, [playersQuery.data])

	useEffect(() => {
		const allTeams = teamsQuery.data?.leaderboard || []
		if (teamsPage === 0 && allTeams.length > 0) {
			setTeams(allTeams.slice(0, 10))
		}
		eventTracking('use_invenue_tv', {
			user_id: profile?.id
		})
	}, [teamsQuery.data])

	useEffect(() => {
		const allPlayers = playersQuery.data?.leaderboard || []
		if (allPlayers.length > 0) {
			const startPlayersOne = playersPageOne * 10
			const endPlayersOne = (playersPageOne + 1) * 10
			const startPlayersTwo = playersPageTwo * 10
			const endPlayersTwo = (playersPageTwo + 1) * 10
			setPlayers(allPlayers.slice(startPlayersOne, endPlayersOne))
			setPlayersTwo(allPlayers.slice(startPlayersTwo, endPlayersTwo))
		} else {
			setPlayers([])
		}
	}, [playersPage])

	useEffect(() => {
		const allTeams = teamsQuery.data?.leaderboard || []
		if (allTeams.length > 0) {
			const startTeams = teamsPage * 10
			const endTeams = (teamsPage + 1) * 10
			setTeams(allTeams.slice(startTeams, endTeams))
		} else {
			setTeams([])
		}
	}, [teamsPage])

	useEffect(() => {
		const timer = setTimeout(() => {
			setClock(clock + 1)
		}, 4000)
		return () => clearTimeout(timer)
	}, [clock])

	// useEffect(() => {
	// 	if (!groupId) {
	// 		navigate('/')
	// 	}
	// }, [])

	useEffect(() => {
		socket.on('connect', () => {
			console.log('connected')
		})

		socket.on('leaderboards', async (data) => {
			console.log('update leaderboards', data)
			await queryClient.invalidateQueries(['leaderboards'])
			await playersQuery.refetch()
			await teamsQuery.refetch()
		})

		socket.on('disconnect', () => {
			console.log('disconnected')
		})

		return () => {
			socket.off('connect')
			socket.off('disconnect')
			socket.off('leaderboards')
		}
	}, [])

	if (!bookingId || !bookingQuery?.data?.group?.id) {
		return <></>
	}

	return (
		<Container
			variant="dark"
			actualheight={height}
			position="relative"
			display="flex"
			flexDirection="column"
			p="0"
		>
			<Flex flex="1" direction="column" pt={calculateHeightValue(150)}>
				<Flex justifyContent="center">
					<Heading
						as="h1"
						fontSize={`${calculateWidthValue(140)} !important`}
						lineHeight={`${calculateWidthValue(130)} !important`}
						color="white"
					>
						{bookingQuery?.data?.group?.name || 'Group'} <br />
						Leaderboard
					</Heading>
				</Flex>

				<Flex
					flex="1"
					mt={calculateHeightValue(80)}
					px={calculateWidthValue(75)}
				>
					<Flex
						flex="1"
						direction="column"
						position="relative"
						overflow="hidden"
						mr={calculateWidthValue(36)}
					>
						<StaticImage
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								bottom: 0,
								right: 0
							}}
							alt="Banner"
							src="../images/board-gold.png"
						/>
						<Text
							as="h1"
							variant="titleTV"
							zIndex="2"
							minH={calculateHeightValue(136)}
						>
							Players
						</Text>
						<Box
							w="100%"
							zIndex="3"
							px={calculateWidthValue(38)}
							flex="1"
						>
							<FlipMove
								enterAnimation="fade"
								leaveAnimation="fade"
								maintainContainerHeight="true"
							>
								{players.map((item, index) => {
									return (
										<LeaderboardItem
											key={`${item?.playerId}-${item.groupId}`}
											item={item}
											gamesPlayed={item.games}
											number={
												index + 1 + playersPageOne * 10
											}
											theme="TV"
											active={true}
										/>
									)
								})}
							</FlipMove>
						</Box>
						<Flex
							w="100%"
							zIndex="4"
							px={calculateWidthValue(38)}
							py={calculateHeightValue(75)}
							justifyContent="flex-end"
						>
							<Text
								variant="xl"
								fontSize={calculateWidthValue(75)}
								lineHeight={calculateWidthValue(90)}
								color="black"
							>
								{playersPageOne + 1}/{maxPlayersPage}
							</Text>
						</Flex>
					</Flex>
					<Flex
						flex="1"
						direction="column"
						position="relative"
						overflow="hidden"
						mt={calculateHeightValue(40)}
						ml={calculateWidthValue(36)}
					>
						<StaticImage
							style={{
								display: hasTeams ? 'block' : 'none',
								position: 'absolute',
								top: 0,
								left: 0,
								bottom: 0,
								right: 0
							}}
							alt="Banner"
							src="../images/board-grey.png"
						/>
						<StaticImage
							style={{
								display: hasTeams ? 'none' : 'block',
								position: 'absolute',
								top: 0,
								left: 0,
								bottom: 0,
								right: 0
							}}
							alt="Banner"
							src="../images/board-gold2.png"
						/>
						<Text
							as="h1"
							variant="titleTV"
							zIndex="2"
							minH={calculateHeightValue(136)}
							mb={calculateHeightValue(50)}
						>
							{hasTeams ? 'Teams' : ''}
						</Text>
						<Box
							w="100%"
							zIndex="3"
							px={calculateWidthValue(38)}
							flex="1"
						>
							<FlipMove
								enterAnimation="fade"
								leaveAnimation="fade"
								maintainContainerHeight="true"
							>
								{(hasTeams ? teams : playersTwo).map(
									(item, index) => {
										let slug = `${
											item?.playerId || item?.teamId
										}-${item.groupId}`

										let countGamesTemp =
											countGamesPlayed.hasOwnProperty(
												slug
											) && countGamesPlayed[slug]

										if (hasTeams) {
											countGamesTemp =
												countGamesTeamPlayed[
													item?.id
												] || 0
										}

										return (
											<LeaderboardItem
												key={slug}
												item={item}
												gamesPlayed={item.games}
												number={
													index +
													1 +
													(hasTeams
														? teamsPage
														: playersPageTwo) *
														10
												}
												isTeam={hasTeams}
												theme="TV"
												active={true}
											/>
										)
									}
								)}
							</FlipMove>
						</Box>
						<Flex
							w="100%"
							zIndex="4"
							px={calculateWidthValue(38)}
							py={calculateHeightValue(75)}
							justifyContent="flex-end"
						>
							{(hasTeams || playersPageTwo < maxPlayersPage) && (
								<Text
									variant="xl"
									fontSize={calculateWidthValue(75)}
									lineHeight={calculateWidthValue(90)}
									color="black"
								>
									{(hasTeams ? teamsPage : playersPageTwo) +
										1}
									/{hasTeams ? maxTeamsPage : maxPlayersPage}
								</Text>
							)}
						</Flex>
					</Flex>
				</Flex>

				<Flex h={calculateHeightValue(600)}>
					<Box
						h={calculateHeightValue(600)}
						w={calculateWidthValue(531)}
						position="relative"
					>
						<StaticImage
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								bottom: 0,
								right: 0
							}}
							alt="Duck"
							src="../images/duck-tv.png"
						/>
					</Box>
					<Flex
						flex="1"
						mt={calculateHeightValue(90)}
						ml={calculateWidthValue(-50)}
						mr={calculateWidthValue(75)}
						pl={calculateWidthValue(190)}
						pr={calculateWidthValue(70)}
						py={calculateHeightValue(85)}
						position="relative"
						h={`${calculateHeightValue(372)} !important`}
					>
						<Heading
							as="h5"
							fontSize={`${calculateWidthValue(60)} !important`}
							lineHeight={`${calculateWidthValue(72)} !important`}
							textAlign="left"
							color="white"
							m="0"
						>
							Only the top 60 scores will be shown on this
							leaderboard. Not on the board? All scores can be
							found on the app!
						</Heading>
						<Box
							position="absolute"
							top="0"
							bottom="0"
							right="0"
							left="0"
						>
							<StaticImage
								style={{ width: '100%', height: '100%' }}
								alt="Duck Bottom"
								src="../images/note-tv.png"
							/>
						</Box>
					</Flex>
				</Flex>
			</Flex>
		</Container>
	)
}

export default VenueTVPage
