import React from 'react'
import VenueTVPage from '../pagesComponents/venue-tv'
import Layout from '../layout'

const VenueTv = ({ location }) => {
	const params = new URLSearchParams(location.search)
	const bookingId = params.get('bookingId')?.toString() || null

	return (
		<Layout title="Corporate TV">
			<VenueTVPage bookingId={bookingId} />
		</Layout>
	)
}

export default VenueTv
